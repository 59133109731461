import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";

import Layout from "../templates/Layout/Layout";
import PostSample from "../components/PostSample/PostSample";
import "./index.css";

import HeaderImage from "../images/banner-image.png";

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import "@fortawesome/fontawesome-svg-core/styles.css";
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;

const IndexPage = ({ data }) => {
  const {
    allMarkdownRemark: { edges: posts },
  } = data;

  return (
    <Layout>
      <figure className="banner-image">
        <img src={HeaderImage} alt="experiences of an ops guy banner image" />
      </figure>
      <div className="index__posts">
        {posts.map(post => (
          <PostSample
            date={post.node.frontmatter.date}
            excerpt={post.node.excerpt}
            key={post.node.frontmatter.path}
            path={post.node.frontmatter.path}
            summary={post.node.frontmatter.summary}
            tags={post.node.frontmatter.tags}
            title={post.node.frontmatter.title}
          />
        ))}
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            date(formatString: "D MMMM YYYY")
            path
            tags
            title
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;
