import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faTag } from "@fortawesome/free-solid-svg-icons";
import PostSampleCard from "../PostSampleCard/PostSampleCard";
import "./PostSample.css";

const PostSample = ({ date, excerpt, path, tags, summary, title }) => (
  <section className="post-sample">
    <PostSampleCard>
      <Link aria-label={title} className="post-sample__link" to={path}>
        <h2 className="post-sample__title">{title}</h2>
      </Link>
      <div className="post-sample__summary">{summary ? summary : excerpt}</div>
      <Link className="post-sample__readmore" to={path}>
        <p>Read more...</p>
      </Link>
      <hr className="separator"></hr>
      <div>
        <div className="post-sample__date">
          <FontAwesomeIcon icon={faClock} fixedWidth />
          {date}
        </div>
        <div className="post-sample__tags">
          <FontAwesomeIcon icon={faTag} fixedWidth />
          {tags.join(", ")}
        </div>
      </div>
    </PostSampleCard>
  </section>
);

PostSample.propTypes = {
  date: PropTypes.string,
  excerpt: PropTypes.string,
  path: PropTypes.string,
  tags: PropTypes.array,
  summary: PropTypes.string,
  title: PropTypes.string,
};

export default PostSample;
